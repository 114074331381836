@font-face {
  font-family: 'Barley Rough';
  src: url("../../../public/fonts/barley-rough-regular.otf");
  font-display: swap;
}
@font-face {
  font-family: 'Andalusia';
  src: url("../../../public/fonts/andalusia.ttf");
  font-display: swap;
}
@font-face {
  font-family: 'Trade Gothic Lt Std';
  src: url("../../../public/fonts/trade-gothic-lt-std.otf");
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: 'Trade Gothic Lt Std';
  src: url("../../../public/fonts/trade-gothic-lt-std-bold.otf");
  font-weight: 700;
  font-display: swap;
}
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');
body {
  @apply text-sm md:text-xl font-['Montserrat'] leading-normal;
}