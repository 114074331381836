.footer {
  clip-path: ellipse(125% 100% at 50% 100%);
	@apply pt-10 md:pt-[92px] bg-cardinal-600 text-white relative z-10 md:mt-[100px] 2xl:mt-[137px];
	&-bg {
		@apply absolute bottom-0 right-0 hidden md:block md:top-[92px] z-10;
		img {
			@apply h-full
		}
	}
	a:hover {
		@apply underline;
	}
	&-content {
		@apply grid md:grid-cols-6 gap-x-5 gap-y-10;
	}
	&-menu {
		@apply md:mx-auto flex flex-col gap-4;
	}
	&-column {
		@apply md:col-span-2 flex items-end;
		&:nth-child(n+1):nth-child(-n+2) {
			@apply md:col-span-3 items-start;
		}
	}
	&-wrapper {
		@apply flex flex-col flex-grow;
	}
	&-icon {
		@apply w-9 inline-block;
	}
	.richtext, .ezrichtext-field {
		@apply flex flex-col gap-4 text-lg;
		a {
			@apply underline text-inherit;
		}
	}
	&-copyright {
		@apply border-t border-current flex items-center justify-center gap-4 mt-5 pt-5 pb-8;
		.copyright{
			&-text{
				@apply text-base;
			}
		}
	}
}