.product-card {
	$self: &;
	@apply flex flex-col w-full p-5 flex-grow;
	&.type-default {
		#{$self}-wrapper {
			@apply flex flex-col bg-white shadow-lg rounded-b-full mt-[40%] flex-grow;
		}
		#{$self}-image {
			@apply -mx-5;
			img {
				@apply w-full object-center object-contain aspect-[400/267] mt-[-40%];
			}
		}
		#{$self}-titles {
			@apply leading-tight flex flex-col justify-center flex-grow;
			#{$self}-pretitle {
				@apply font-medium text-sm md:text-xl px-4 md:px-8 text-center;
			}
			#{$self}-title {
				@apply pt-0 pb-10 px-5 text-base md:pb-16 md:px-16 md:text-2xl font-bold text-center;
			}
		}
	}
	&.type-combo {
		#{$self}-wrapper {
			@apply flex flex-col bg-white shadow-lg rounded-b-2xl flex-grow;
		}
		#{$self}-image {
			img {
				@apply w-full object-center object-contain aspect-[400/247];
			}
		}
		#{$self}-titles {
			@apply leading-tight flex flex-col justify-start flex-grow py-5 md:py-10 px-5;
			#{$self}-pretitle {
				@apply font-medium text-sm md:text-[16px] px-5 text-center;
			}
			#{$self}-title {
				@apply text-lg md:text-[22px] font-bold text-center;
			}
		}
	}
	#{$self}-title {
		@apply text-cardinal-600;
	}
	.swiper-slide:nth-child(even) &{
		#{$self}-title {
			@apply text-[#000];
		}
	}
}