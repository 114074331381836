.section {
	$self: &;
	$block: #{$self}-block;
	&-wrapper{
		@apply flex flex-col gap-10 md:gap-[42px];
		#{$block}:not(.static-banner, .about-us) &{
			@apply py-10 md:py-16;
		}
		#{$block}.products-slide &{
			@apply gap-5;
		}
		#{$block}.about-us &{
			@apply relative -z-10;
		}
		#{$block}.static-banner &{
			@apply py-20 min-h-[300px] md:min-h-[400px] justify-center text-white;
		}
	}
	&-block{
		@apply relative z-10;
		.slide-wrapper {
			@apply w-full;
			.flicking-viewport {
				@apply overflow-visible;
			}
		}
		&-bg {
			@apply absolute inset-0 -z-10;
			img {
				@apply w-full h-full object-cover;
			}
		}
		&-overlay {
			#{$block}.about-us &{
				@apply box-content absolute h-full left-0 right-0 bottom-1/3 lg:bottom-0 lg:right-1/2 -z-10;
			}
			#{$block}.about-us.span-bg &{
				@apply pt-[20%];
			}
		}
		&.static-banner{
			@apply bg-center bg-cover bg-cardinal-600;
			.section-wrapper{
				@apply pt-[106px] pb-[96px];
			}
		}
		&-wrapper {
			@apply max-w-7xl px-3.5 md:px-5 mx-auto;
			#{$block}.about-us & {
				@apply relative z-10 py-8 md:py-16 lg:py-20 grid gap-6 md:gap-10 lg:grid-cols-2 xl:grid-cols-[35fr_65fr] xl:gap-14;
			}
			#{$block}.products-slide & {
				@apply flex gap-5 items-center;
			}
			.block {
				&-content{
					@apply relative z-10 flex flex-col gap-6 md:gap-10;
					&-titles {
						@apply flex flex-col;
					}
					&-pretitle {
						@apply text-2xl md:text-3xl;
					}
					&-title {
						@apply text-2xl md:text-4xl leading-none lg:mr-[-50%]
					}
				}
				&-gallery {
					@apply relative w-full md:w-3/4 lg:w-full mx-auto;
					&-grid {
						@apply grid grid-cols-[30fr_35fr_45fr] xl:grid-cols-[42fr_28fr_30fr] gap-5 items-end;
					}
					&-img {
						img {
							@apply w-full;
						}
						&:nth-child(1){
							@apply col-span-3 xl:col-span-2 row-start-1 row-end-2 lg:-mt-10 xl:mt-0 xl:row-end-3;
						}
						&:nth-child(2){
							@apply col-span-2 xl:col-span-1 xl:mb-[-20%] self-start;
						}
						&:nth-child(3){
							@apply col-span-2 xl:col-span-1 md:-ml-5 xl:ml-0;
						}
						&:nth-child(4){
							@apply self-end col-start-3 row-start-2 row-end-4 xl:col-start-3 xl:row-start-1 xl:row-end-4 xl:mt-[-50%];
						}
						&-wrapper {
							@apply relative overflow-hidden rounded-2xl;
						}
						&-overlay {
							@apply absolute inset-0 bg-gradient-to-r from-black/[0.4] to-transparent;
						}
					}
				}
			}
		}
		&-pretitle {
			@apply 2xl:text-[24px] text-center;
			+ * {
				@apply mt-10 md:mt-[42px];
			}
		}
		&-titles {
			#{$block}.static-banner &{
				@apply text-center leading-none;
				#{$block}-pretitle{
					@apply font-andalusia text-3xl md:text-6xl 2xl:text-[109px];
				}
				#{$block}-title{
					@apply font-barley text-4xl md:text-7xl 2xl:text-[150px] tracking-[-0.045em];
				}
				.section-block-wrapper{
					@apply max-w-none;
				}
			}
			#{$block}.products-slide &{
				#{$block}-title{
					@apply font-barley text-xl md:text-2xl text-cardinal-600 flex-grow;
				}
			}
			#{$block}:not(.static-banner, .products-slide) &{
				#{$block}-title{
					@apply font-trade text-2xl md:text-4xl lg:text-5xl font-bold text-center;
				}
			}
		}
	}
}