.type{
	&-paper{
		ul {
			background: url("../../../public/images/paper.webp") bottom / 1.82em;
			box-shadow: 0 0 10px #0000001a;
			line-height: 1.8!important;
			@apply max-w-[872px] m-auto text-xl md:text-2xl lg:text-3xl font-trade relative py-[1.8em] pl-[1em] md:pl-[3em];
			li {
					padding: 0 0.75em;
			}
			&::before {
				content: '';
				@apply  absolute inset-y-0 border-l border-cardinal-600;
			}
		}
	}

	&-card {
		&.onBorder {
			ol {
				outline: #fff solid 1px;
				outline-offset: -20px;
			}
		}
		ol {
				box-shadow: 0 0 10px #0000001a;
				line-height: 1.65!important;
				@apply max-w-[872px] m-auto bg-cardinal-600 text-white p-[2em] md:p-[60px] text-xl md:text-2xl lg:text-3xl font-trade list-decimal;
				li {
					@apply ml-[1.8em] scale-x-[0.81];
				}
		}
	}

	&-note{
		@apply font-trade text-2xl md:text-3xl lg:text-4xl text-center;
	}

}