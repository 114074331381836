.panel-menu {
	background-image: url("../../../public/images/bg-pizza.webp");
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: 60%;
	@apply flex flex-col gap-10 p-12 pt-24 transition duration-500 max-w-[500px] w-full fixed right-0 inset-y-0 bg-cardinal-600 z-50 overflow-y-auto overflow-x-hidden;
  .item-menu {
    $link: &;
    .link-active {
      @apply  h-1.5 w-20 bg-white absolute top-full right-0 translate-x-full opacity-0 transition;
    }
    &:hover, &.active{
      .link-active {
        @apply opacity-100 translate-x-1/2;
      }
    }
  }
}