// clipPath: 'ellipse(130% 100% at 50% 0%)
.content-wrapper {
	@apply max-w-7xl px-5 mx-auto;
}
.layout {
	$self: &;
	&-header {
		clip-path: ellipse(160% 100% at 50% 0%);
		@apply pt-32 pb-14 md:pt-48 md:pb-20 text-center text-white bg-cardinal-600 relative z-10;
		.bg-header {
			@apply absolute inset-0 -z-10;
			img {
				@apply w-full h-full object-cover object-center;
			}
		}
		#{$self}-title {
			@apply font-barley text-3xl md:text-5xl lg:text-6xl;
		}
	}
	&-wrapper {
		@apply flex flex-col;
	}
	&-content{
		@apply py-10 md:py-16;
		& + * {
			@apply -mt-10 md:-mt-16
		}
	}

}