.slide-controls {
	@apply flex gap-3 md:gap-5;
	.arrow-slide {
		@apply flex w-6 md:w-10 cursor-pointer aspect-[1] z-10 bg-cardinal-600 text-white transition;
	}
	x-icon{
		@apply m-auto w-1/2;
	}
}
.hidden-items {
	& > * {
		@apply hidden;
	}
}
.swiper-slide {
	@apply h-auto flex flex-col;
}
[x-data-slide] {
	@apply -m-5 overflow-visible;
}