@import "font/body";
@import "layouts/layouts";
@import "layouts/blocks";
@import "componentes/sliders";
@import "componentes/product-card";
@import "componentes/banners";
@import "componentes/header";
@import "componentes/footer";
@import "richtext/richtext";

body {
	@apply overflow-x-hidden;
}
img[data-src ] {
	@apply opacity-0;
}
.main-body {
	@apply relative z-10;
}